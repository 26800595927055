<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="lsn-main-area">
        <div class="page">
            <div class="page-header">
                <div class="page-header__title">
                    Articles Management
                </div>
            </div>

            <div class="page-toolbar">
                <div class="lsn-toolbar__search">
                    <input v-model="searchText" type="text" class="lsn-toolbar__search-field" />
                </div>

                <div class="lsn-btn lsn-btn-icon lsn-btn--primary-outline">
                    <mdi-icon class="hw-4" icon="mdiFilter" />
                </div>

                <div class="ml-4 lsn-btn lsn-btn--primary" @click="showNewArticleForm">
                    New Article
                </div>

                <div
                    v-if="$route.params.article_id"
                    class="ml-4 lsn-btn lsn-btn--primary-outline"
                    @click="showDuplicateArticleForm"
                >
                    Duplicate
                </div>
            </div>

            <div class="page-content">
                <div class="w-1/4">
                    <articles-list v-if="!loading" :search-text="searchText" />

                    <div v-else>
                        <img src="/img/loader-761.svg" alt="Loading..." class="mx-auto mt-4">
                    </div>
                </div>

                <perfect-scrollbar class="w-3/4 p-4">
                    <article-details v-if="$route.params.article_id" />

                    <div v-else-if="!loading">
                        Select an article in the list on the left to see its details here.
                    </div>
                </perfect-scrollbar>
            </div>
        </div>

        <!-- Side bar: Edition form -->
        <router-view v-slot="{ Component }" :key="siderKey" name="sider">
            <lsn-sider :is-open="$route.meta.isSiderOpen && !loading" class="w-1/3">
                <component :is="Component" />
            </lsn-sider>
        </router-view>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import ArticlesList   from './ArticlesList.vue';
import ArticleDetails from './ArticleDetails.vue';

export default
{
    name: 'Articles',

    components:
    {
        ArticlesList,
        ArticleDetails,
    },

    data()
    {
        return {
            loading:    true,
            searchText: '',
        };
    },

    computed:
    {
        siderKey()
        {
            return this.$route.fullPath + '/sider';
        },
    },

    created()
    {
        // Retrieve all existing articles and tags
        this.loading = true;

        Promise.all([
            this.$store.dispatch('articles/fetchAll'),
            this.$store.dispatch('tags/fetchAll'),
        ])
            .then()  // nothing
            .catch() // nothing
            .then(() =>
            {
                this.loading = false;
            });
    },

    methods:
    {
        /**
         * Navigate to the "New Article" form.
         */
        showNewArticleForm()
        {
            this.$router.push({ name: 'new-article' });
        },

        showDuplicateArticleForm()
        {
            this.$router.push({
                name:   'duplicate-article',
                params: {
                    article_id: this.$route.params.article_id,
                },
            });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>



</style>
