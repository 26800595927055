/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */

import AppLayout               from '@/layouts/AppLayout.vue';

import Home                    from '@/views/Home.vue';
import Initializing            from '@/views/Initializing.vue';
import Login                   from '@/views/Login.vue';

import Articles                from '@/views/articles/Articles.vue';
import ArticleForm             from '@/views/articles/ArticleForm.vue';
import DuplicateArticleForm    from '@/views/articles/DuplicateArticleForm.vue';
import ArticleTagsForm         from '@/views/articles/ArticleTagsForm.vue';
import ArticleSupplementsForm  from '@/views/articles/ArticleSupplementsForm.vue';
import ArticlePropertiesForm   from '@/views/articles/ArticlePropertiesForm.vue';
import ArticlePackContentsForm from '@/views/articles/ArticlePackContentsForm.vue';

import Tags                    from '@/views/tags/Tags.vue';
import TagForm                 from '@/views/tags/TagForm.vue';

import PriceGroups             from '@/views/price-groups/PriceGroups.vue';
import PriceGroupsFilter       from '@/views/price-groups/PriceGroupsFilter.vue';

import Tools                   from '@/views/tools/Tools.vue';

export default[

    // -----------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------- UNAUTHENTICATED
    // -----------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------- LOGIN

    {
        path:      '/login',
        name:      'login',
        component: Login,
        meta:      { auth: false }, // If the user needs to be a guest to view this page.
    },

    // ---------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------- AUTHENTICATED
    // ---------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------- INITIALISATION

    {
        path:      '/initializing',
        name:      'initializing',
        component: Initializing,
        meta:      { auth: true },
    },

    // ------------------------------------------------------------------------- BASE

    {
        path:      '/',
        name:      'base',
        component: AppLayout,
        meta:      { auth: true }, // If the user needs to be authenticated to view this page.

        children:
        [
            // ----------------------------------------------------------------- HOME

            {
                path:      '/home',
                name:      'home',
                component: Home,
            },

            // ----------------------------------------------------------------- ARTICLES

            {
                path:      '/articles',
                name:      'articles',
                component: Articles,
                meta:
                {
                    isSiderOpen: false,
                },
                children:
                [
                    // --------------------------------------------------------- New Article
                    {
                        path: '/articles/new',
                        name: 'new-article',
                        components:
                        {
                            sider: ArticleForm,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },
                ],
            },

            {
                path:      '/articles/:article_id',
                name:      'view-article',
                component: Articles,
                meta:
                {
                    isSiderOpen: false,
                },
                children:
                [
                    // --------------------------------------------------------- Edit Article
                    {
                        path: '/articles/:article_id/edit',
                        name: 'edit-article',
                        components:
                        {
                            sider: ArticleForm,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },

                    // --------------------------------------------------------- Duplicate Article Form
                    {
                        path: '/articles/:article_id/duplicate',
                        name: 'duplicate-article',
                        components:
                        {
                            sider: DuplicateArticleForm,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },

                    // --------------------------------------------------------- Edit Article Tags
                    {
                        path: '/articles/:article_id/edit-tags',
                        name: 'edit-article-tags',
                        components:
                        {
                            sider: ArticleTagsForm,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },

                    // --------------------------------------------------------- Edit Article Supplements/Appliances
                    {
                        path: '/articles/:article_id/edit-supplements',
                        name: 'edit-article-supplements',
                        components:
                        {
                            sider: ArticleSupplementsForm,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },

                    {
                    // --------------------------------------------------------- Edit Article Properties
                        path: '/articles/:article_id/edit-properties',
                        name: 'edit-article-properties',
                        components:
                        {
                            sider: ArticlePropertiesForm,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },

                    // --------------------------------------------------------- todo: Edit Article Pack Contents
                    {
                        path: '/articles/:article_id/edit-pack-contents',
                        name: 'edit-article-pack-contents',
                        components:
                        {
                            sider: ArticlePackContentsForm,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },
                ],
            },


            // ----------------------------------------------------------------- TAGS

            {
                path:      '/tags',
                name:      'tags',
                component: Tags,
                meta:
                {
                    isSiderOpen: false,
                },
                children:
                [
                    // --------------------------------------------------------- New Tag
                    {
                        path: '/tags/new',
                        name: 'new-tag',
                        components:
                        {
                            sider: TagForm,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },
                ],
            },

            {
                path:      '/tags/:tag_id',
                name:      'view-tag',
                component: Tags,
                meta:
                {
                    isSiderOpen: false,
                },
                children:
                [
                    // --------------------------------------------------------- Edit Tag
                    {
                        path: '/tags/:tag_id/edit',
                        name: 'edit-tag',
                        components:
                        {
                            sider: TagForm,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },
                ],
            },


            // ----------------------------------------------------------------- PRICE GROUPS

            {
                path:      '/price-groups/:date(\\d{4}-\\d{2}-\\d{2})?/:price_groups?',
                name:      'price-groups',
                component: PriceGroups,
                meta:
                {
                    isSiderOpen: false,
                },
                children:
                [
                    {
                        path: '/price-groups/:date(\\d{4}-\\d{2}-\\d{2})?/:price_groups?/select',
                        name: 'select-price-groups',
                        components:
                        {
                            sider: PriceGroupsFilter,
                        },
                        meta:
                        {
                            isSiderOpen: true,
                        },
                    },
                ],
            },


            // ----------------------------------------------------------------- TOOLS

            {
                path:      '/tools',
                name:      'tools',
                component: Tools,
            },
        ],
    },


    // --------------------------------------------------------------------------------
    // ----------------------------------------------------------------- DEFAULT ROUTES
    // --------------------------------------------------------------------------------

    {
        path:     '/',
        redirect: { name: 'login' },
    },

    {
        path:     '/*',
        redirect: { name: 'login' },
    },
];
