<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-sider cs-article-form text-gray-500">
        <!-- Header -->
        <header class="cs-sider__header">
            <div>
                Duplicate Article
            </div>

            <div class="flex-grow"></div>

            <div v-if="loading">
                <img src="/img/loader-5.gif" alt="..." class="inline w-4 h-4">
            </div>
        </header>

        <!-- Form -->
        <div class="cs-sider__content p-4">
            <div class="mb-4 border-l-2 border-l-primary-400 p-4 bg-primary-10 text-primary-500 text-sm space-y-4">
                <p>
                    Please choose a unique code and a label for the new article.
                </p>

                <p>
                    Tags and supplements will be attached to the new article.<br>
                    Any existing extra properties will be copied.
                </p>
            </div>

            <lsn-input
                ref="code"
                v-model="code"
                class="lsn-form__wrapper--padless mb-4"
                label="Code"
                ckey="code"
                :error="errors.code"
            />

            <lsn-input
                v-model="label"
                class="lsn-form__wrapper--padless mb-4"
                label="Label"
                ckey="label"
                :error="errors.label"
            />

            <p v-if="errorMessage" class="lsn-form__error mb-4">
                {{ errorMessage }}
            </p>
        </div>

        <div class="cs-sider__actions">
            <div class="lsn-field-wrapper">
                <div class="lsn-btn lsn-btn--primary-outline" @click="cancel">
                    Cancel
                </div>
            </div>

            <div class="lsn-field-wrapper ml-3">
                <div
                    class="lsn-btn"
                    :class="!loading ? 'lsn-btn--primary' : 'lsn-btn--disabled'"
                    @click="save"
                >
                    Save
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'DuplicateArticleForm',

    data()
    {
        return {
            loading:      false,
            code:         '',
            label:        null,
            errorMessage: '',
            errors:       {},
        };
    },

    computed:
    {
        articleId()
        {
            return parseInt(this.$route.params.article_id);
        },
    },

    created()
    {
        const article = this.$store.getters['articles/oneById'](this.articleId);
        this.code = article.code;
        this.label = article.label;
    },

    methods:
    {
        cancel()
        {
            this.$router.push({
                name:   'view-article',
                params: { article_id: this.articleId },
            });
        },

        save()
        {
            if(this.loading)
            {
                return;
            }

            this.loading = true;

            const data = {
                id:    this.articleId,
                code:  this.code,
                label: this.label,
            };
            this.$store.dispatch('articles/duplicate', data)
                .then(copy =>
                {
                    this.errorMessage = '';
                    this.errors = {};

                    this.$nextTick(() =>
                    {
                        this.$router.push({
                            name:   'edit-article',
                            params: { article_id: copy.id },
                        });
                    });
                })
                .catch(error =>
                {
                    // Set field-specific errors
                    if(error.response?.errors)
                    {
                        this.errors = error.response.errors;
                    }

                    if(Object.keys(this.errors).length)
                    {
                        this.errorMessage = 'Please check fields for validation errors.';
                        return;
                    }

                    // Set error message
                    if(error.response?.message)
                    {
                        this.errorMessage = error.response.message;
                    }
                    else if(error.message)
                    {
                        this.errorMessage = error.message;
                    }
                    else if(typeof error === 'string')
                    {
                        this.errorMessage = error;
                    }
                })
                .then(()=>
                {
                    this.loading = false;
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
