<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-list">
        <perfect-scrollbar class="cs-list__wrapper">
            <!-- Articles -->
            <div v-if="articles.length" class="cs-list__section-header">
                Articles
            </div>

            <div
                v-for="article in articles"
                :key="article.id"
                class="cs-list__item"
                :class="getItemClass(article)"
                @click="selectArticle(article.id)"
            >
                <div class="cs-list__item-label">
                    {{ article.label }}
                </div>

                <div class="cs-list__item-code">
                    {{ article.code }}
                </div>
            </div>

            <!-- Supplements -->
            <div v-if="supplements.length" class="cs-list__section-header">
                Supplements
            </div>

            <div
                v-for="supplement in supplements"
                :key="supplement.id"
                class="cs-list__item"
                :class="getItemClass(supplement)"
                @click="selectArticle(supplement.id)"
            >
                <div class="cs-list__item-label">
                    {{ supplement.label }}
                </div>

                <div class="cs-list__item-code">
                    {{ supplement.code }}
                </div>
            </div>

            <!-- Packs -->
            <div v-if="packs.length" class="cs-list__section-header">
                Packs
            </div>

            <div
                v-for="pack in packs"
                :key="pack.id"
                class="cs-list__item"
                :class="getItemClass(pack)"
                @click="selectArticle(pack.id)"
            >
                <div class="cs-list__item-label">
                    {{ pack.label }}
                </div>

                <div class="cs-list__item-code">
                    {{ pack.code }}
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { sortBy } from 'lodash-es';
import Fuse       from 'fuse.js';

const SORT_BY_LABEL = (a, b) => a.label < b.label ? -1 : (a.label > b.label ? 1 : 0);

export default
{
    name: 'ArticlesList',

    props:
    {
        searchText:
        {
            type:    String,
            default: '',
        },
    },

    data()
    {
        return {
            fuse: null,
        };
    },

    computed:
    {
        items()
        {
            return this.$store.getters['articles/sortedArticles'];
        },

        foundItems()
        {
            let items = this.items;

            if(this.searchText.length >= 2)
            {
                items = this.fuse.search(this.searchText).map(res => res.item);
            }

            return items;
        },

        articles()
        {
            return this.foundItems
                .filter(i => i.type === this.$const.articles.type.ARTICLE)
                .sort(SORT_BY_LABEL);
        },

        supplements()
        {
            return this.foundItems
                .filter(i => i.type === this.$const.articles.type.SUPPLEMENT)
                .sort(SORT_BY_LABEL);
        },

        packs()
        {
            return this.foundItems
                .filter(i => i.type === this.$const.articles.type.PACK)
                .sort(SORT_BY_LABEL);
        },
    },

    watch:
    {
        '$store.state.articles.all': {
            handler()
            {
                this.initialize();
            },
            deep: true,
        },
    },

    created()
    {
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            this.fuse = new Fuse(this.items,
                {
                    keys:           ['label', 'code'],
                    threshold:      0, // exact match
                    ignoreLocation: true,
                });
        },

        getItemClass(article)
        {
            const cssClasses = [
                article.status == 'ACTIVE'
                    ? 'cs-list__item--active'
                    : 'cs-list__item--disabled',
            ];

            if(article.id == this.$route.params.article_id)
            {
                cssClasses.push('cs-list__item--selected');
            }

            return cssClasses;
        },

        /**
         * Select an article for display & edition.
         */
        selectArticle(articleId)
        {
            this.$router.push({ name: 'view-article', params: { article_id: articleId } });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
